import React from 'react'
import starIcon from '../../assets/images/star-icon.png'
import serviceIcon1 from '../../assets/images/services/service-icon1.png'
import serviceIcon2 from '../../assets/images/services/service-icon2.png'
import serviceIcon3 from '../../assets/images/services/service-icon3.png'
import serviceIcon4 from '../../assets/images/services/service-icon4.png'
import serviceIcon5 from '../../assets/images/services/service-icon5.png'
import serviceIcon6 from '../../assets/images/services/service-icon6.png'

const OurFeatures = () => {
    return (
        <section className="services-area pt-100 pb-70 bg-f1f8fb" id="theparse-edge">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="feature" /> 
                        Project Implementation
                    </span>

                    <h2>Our Suggested Approach</h2>
                    <p>Diving into implementation can be risky and costly – Neolytics suggests an approach that ensures we take advantage of quick wins and early benefits</p>
                </div>

                <div className="row">
                    {/* <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon2} alt="feature" />
                            </div>
                            <h3>Email Notifications</h3>
                            <p>Our out of the box software solution has email integration giving you the flexibility to automate communication with employees.</p>
                        </div>
                    </div> */}
                    
                    {/* <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon4} alt="feature" />
                            </div>
                            <h3>Information Retrieval</h3>
                            <p>At theParse we are skilled at information retrieval. Turn data into insight today!</p>
                        </div>
                    </div> */}
                    
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon5} alt="feature" />
                            </div>
                            <h3>Diagnostic</h3>
                            <p><b>The Problem Definition stage:</b> This process aims to narrow the scope of which areas in the business are ready for and will benefit from a predictive analytics solution – minimising wasted investment without clear return, employee buy-in or roadmap for execution.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon3} alt="feature" />
                            </div>
                            <h3>Phase 1 : Implementation</h3>
                            <p><b>Laying the groundwork:</b> Once we know the area of the business prioritised for change, we work with you to close the data infrastructure gaps for advanced analytics roll-out.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon1} alt="feature" />
                            </div>
                            <h3>Phase 2 : Implementation</h3>
                            <p><b>Leveraging the full benefit of data: </b>Once we have identified priority benefit areas and set-up the infrastructure we dive into predictive and prescriptive analytics.</p>
                        </div>
                    </div>
                    
                    {/* <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon6} alt="feature" />
                            </div>
                            <h3>Resource Management</h3>
                            <p>Looking for a low-cost solution to better manage your staff, your resources and capabilities?</p>
                        </div>
                    </div> */}
                </div>
            </div>
        </section>
    )
}

export default OurFeatures