import React from 'react'
import service1 from '../../assets/images/services/service1.png'
import service2 from '../../assets/images/services/service2.png'
import starIcon from '../../assets/images/star-icon.png'

const OurServices = () => {
    return (
        <React.Fragment>
            {/* Service Left Image Style */}
            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img src={service1} alt="service" />
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content">
                                    <span className="sub-title">
                                        <img src={starIcon} alt="icon" /> Our Focus
                                    </span>
                                    
                                    <h2>Our Industries</h2>
                                    <p>We have a primary focus on the heavy industry.</p>
                                    <ul className="about-list mb-0">
                                        <li>
                                        <i className="flaticon-tick"></i>
                                            Mining Operations
                                        </li>
                                        <li>
                                        <i className="flaticon-tick"></i>
                                            Plants & Processing
                                        </li>
                                        <li>
                                        <i className="flaticon-tick"></i>
                                            Manufacturing
                                        </li>
                                        <li>
                                        <i className="flaticon-tick"></i>
                                            Oil & Gas
                                        </li>
                                        <li>
                                        <i className="flaticon-tick"></i>
                                            Logistics & Fleet Management
                                        </li>
                                        <li>
                                        <i className="flaticon-tick"></i>
                                            Building & Construction
                                        </li> 
                                        {/* <li>
                                            <i className="flaticon-tick"></i>
                                            Hybrid Cloud
                                        </li>  */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Service Left Image Style */}

            {/* Service Right Image Style */}
            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content">
                                    <span className="sub-title">
                                        <img src={starIcon} alt="icon" />Shaping the future
                                    </span>
                                    
                                    <h2>Our Value Proposition</h2>
                                    <p>We offer a variety of services within the mining sector with 10+ years of experience and $100m+ in recurring annual client benefits.</p>
                                    <ul className="our-mission-list mb-0">
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Operational efficiency 
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Supply Chain Optimisation
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Capital project optimisation and implementation
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Energy Efficiency
                                        </li>
                                       
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <img src={service2} alt="service" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Service Right Image Style */}
        </React.Fragment>
    )
}

export default OurServices;